import React from 'react';

const Slider = () => {
    return (
        <div className="theme_slider_1">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="slide_content">
                                <h2 className="heading">Enjoy where<br/> you live</h2>
                                <p className="desc">Crafting dreams into reality, one project at a time. Trust JSB Buildings to build the home of your dreams</p>
                                <div className="slider_button">
                                    <a href="/about" className="button">Our Services</a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;