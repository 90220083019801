import React from 'react';

const Experience02 = () => {
    const headerData = {
        heading: 'Where craftsmanship meets innovation in the heart of Sydneys construction landscape.',
        detail: 'With over a decade of experience, we have been at the forefront of residential construction shaping communities and transforming homes one project at a time',
        img: 'images/about/call_img.png',
    };
    return (
        <div className="experience about_bg_image another_bg_image">
            <div className="upper_sm_img">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="group_image_holder">
                                <img src="images/about/2.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="experience_content experience_content_margin ">
                                <div className="section_header">
                                    <h6 className="section_sub_title">{headerData.subheading}</h6>
                                    <h1 className="section_title">{headerData.heading}</h1>
                                    <p className="short_detail">{headerData.detail}</p>
                                    <h3>At JSB Buildings</h3>
                                    <p className="section_desc">We pride ourselves on our ability to deliver turnkey solutions tailored to meet the unique needs of each client</p><br />
                                    <h3>Excellence in all that we do</h3>
                                    <p className="section_desc">Whether it's a simple renovation, a residential apartment build, or a full-scale new construction project, our team of architects, engineers, and project managers are dedicated to ensuring that every project is executed to the highest standards of quality, on time and within budget.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience02;