import React from 'react';
import Video from '../../Components/BannerElements/Video';
import Testimonial01 from '../Testimonials/Testimonial01';

const ServiceContainer05 = () => {
    return (
        <div className="main_wrapper">
            <div className="container">
                <div className="project_details section">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="project_details_inner">
                                <div className="post_img">
                                    <img src="images/services/rez.png" alt="blog" />
                                </div>
                                <div className="post_content">
                                    <div className="post_header">
                                        <h3 className="post_title">JSB Building Services</h3>
                                    </div>
                                    <div className="fulltext">
                                        <p>
                                            JSB Buildings stands as a beacon of excellence in residential extensions, renowned for unparalleled craftsmanship and dedication in catering to discerning homeowners across Sydney. With acclaim as a premier Sydney house extension builder, we uphold an exceptional standard of work within the industry.
                                        </p>
                                        <p>
                                            At JSB Buildings, we take pride in transforming houses into dream homes through meticulously designed and expertly crafted extensions. Guided by a team of specialist in-house designers with a keen eye for detail, every aspect of our house extensions is thoughtfully considered and flawlessly executed.
                                        </p>
                                        <p>
                                            Our commitment to unmatched quality and craftsmanship has earned the trust of countless families, who have found not only enhanced living spaces but also sound investments in their properties. With a deep understanding of the significance of house extensions, we have become the trusted partner for those seeking top-tier extensions in Sydney.
                                        </p>
                                        <div className="post_header">
                                        <h3 className="post_title">Features</h3>
                                    </div>
                                        <ol>
                                                <li><strong>Trusted Team of Tradespeople:</strong> Our experienced tradespeople, with years of dedicated service, bring a wealth of expertise and craftsmanship to every project. From carpenters to electricians, bricklayers to painters, our team possesses all the skills necessary to build extensions to the highest standard.</li>
                                                <li><strong>Premium Materials from Leading Suppliers:</strong> We are selective in sourcing materials, partnering only with leading trade suppliers committed to quality and industry standards, guaranteeing durability and peace of mind for our clients.</li>
                                                <li><strong>Exceptional Work Environment:</strong> JSB Buildings prioritizes a supportive work environment, attracting and retaining top talent, ensuring continuity and consistency in our projects.</li>
                                        </ol>

                                        <p>
                                        Choose JSB Buildings for your residential extension needs and experience unparalleled quality, expert craftsmanship, and personalized service. Your dream home awaits, and we're here to bring it to life with excellence and professionalism.
                                        </p>

                                        <h4 className="widget_title">
                                        Our Working Process

                                            <span className="title_line"></span>
                                        </h4>
                                        <p className="margin_o_para">We possess a proven track record of successfully completing extensive extension and expansion projects for a variety of building types, including schools, leisure centers, churches, and industrial units. Our projects have involved creating additional space and introducing new facilities to cater to the evolving needs of our clients. We are experienced in operating in close proximity to occupied buildings, ensuring minimal disruption while maximizing efficiency and safety throughout the construction process.</p>
                                            <br />
                                        <Video bg='images/services/video_bg.png' />
                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="sidebar">
                                <div id="widgetnav" className="widget widget_menu">
                                    <div className="sidenav">
                                    <ul className="side_menu">
                                            <li className="menu-item active">
                                                <a href="/newbuild">
                                                    <img src="images/services/cone_1.png" alt="" />
                                                    <img src="images/services/2-white.png" alt="" />
                                                    Residential New Build
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/grannyflat">
                                                    <img src="images/services/9-blue.png" alt="" />
                                                    <img src="images/services/cone_2.png" alt="" />
                                                    Residential Granny Flat
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/residentialextensions">
                                                    <img src="images/services/cone_3.png" alt="" />
                                                    <img src="images/services/5-blue.png" alt="" />
                                                    Residential Extensions
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/residentialrenovations">
                                                    <img src="images/services/cone_4.png" alt="" />
                                                    <img src="images/services/1-white.png" alt="" />
                                                    
                                                    Residential Renovations
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="custom_2" className="widget widget_side_contact">
                                    <div className="side_contact">
                                        <div className="side_contact_top">
                                            <div className="side_contact_inner">
                                                <p>LET’S WORK TOGETHER
                                                </p>
                                                <h4>Info@jsbbuildings.com.au
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="side_phone_inner">
                                            <div>(02) 8723 9960
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial01 pb='pd_btom_80 pd_top_80' />
        </div>
    );
};

export default ServiceContainer05;