import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainer07 from '../../Containers/ServiceContainers/ServiceContainer07';

const ResidentialRenovations = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Residential Renovations' heading='Residential Renovations' />
            <ServiceContainer07 />
        </>
    );
};

export default ResidentialRenovations;