import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainer06 from '../../Containers/ServiceContainers/ServiceContainer06';

const ResidentialExtensions = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Residential Granny Flat' heading='Residential Granny Flat' />
            <ServiceContainer06 />
        </>
    );
};

export default ResidentialExtensions;