import React from 'react';
import CountUp from 'react-countup';

const FunBox02 = ({ data }) => {
    const { img, name, number, prefix, suffix } = data; // Add prefix and suffix here

    return (
        <div className="funbox2">
            <div className="fun_img">
                <img src={img} alt="icon" />
            </div>
            <div className="fun_content">
                <h1>
                    <CountUp 
                        start={0} 
                        end={number} 
                        delay={1} 
                        prefix={prefix}  // Add prefix here
                        suffix={suffix}  // Add suffix here
                    >
                        {({ countUpRef }) => (
                            <span className="fun-number" ref={countUpRef} />
                        )}
                    </CountUp>
                </h1>
                <p>{name}</p>
            </div>
        </div>
    );
};

export default FunBox02;
