import $ from 'jquery';
import React, { useEffect } from 'react';

const Video = (props) => {
    useEffect(() => {
        // Youtube
        var $ytvideoTrigger = $(".ytplay_btn");
        $ytvideoTrigger.on("click", function (evt) {
            $(".ytube_video").addClass("play");
            $("#ytvideo")[0].src += "?autoplay=1";
        });

    }, []);
    return (
        <div className="video_post">
            <div className="ytube_video">
                <iframe id="ytvideo" src="https://cdn.prod.website-files.com/65ed4e541d2d4ecefd4f72b5/661db8e4a0d3f997da3e1f0d_AdobeStock_84793487 (1)-transcode.mp4" allow="autoplay;" allowfullscreen></iframe>
                <div className="post_content">
                    <div className="ytplay_btn"><i className="ion-ios-play"></i></div>
                    <img src={props.bg} alt="blog" />
                </div>
            </div>
        </div>
    );
};

export default Video;