import React, { useState } from 'react';
import Partners01 from '../../Components/Partners/Partners01';
import Banner from '../../Containers/Banner';
import Blog from '../../Containers/Blogs/Blog';
import Experience from '../../Containers/Experiences/Experience';
import FunFact01 from '../../Containers/Funfacts/FunFact01';
import ProjectHome01 from '../../Containers/ProjectContainers/ProjectHome01';
import ServiceHome01 from '../../Containers/ServiceContainers/ServiceHome01';
import Testimonial01 from '../../Containers/Testimonials/Testimonial01';
import Slider from '../../Containers/ThemeSliders/Slider';
import ContactHome02 from '../../Containers/Contacts/ContactHome02';


const Home01 = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? -1 : index); // Toggle open/close
    };

    return (
        <div className="main_wrapper">
            <Slider />
            <ServiceHome01 />
            <Experience />
            <FunFact01 />
            <div className="main_wrapper">
                <div className="faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <div className="images-faq">
                                    <img src="images/man.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="section_header">
                                    <h6 className="section_sub_title">OUR SKILL AND EXPERTISE</h6>
                                    <h1 className="section_title">Build better futures with focus on quality</h1>
                                </div>
                                <div className="accordion">
                                    {[
                                        {
                                            title: "What Sets JSB Buildings Apart In The Sydney Construction Market?",
                                            content: "SB Buildings stands out as a leader in residential construction in Sydney, prioritising client satisfaction and community well-being in every project undertaken."
                                        },
                                        {
                                            title: "How Does JSB Buildings Ensure Quality And Efficiency In Its Projects?",
                                            content: "With a dedicated team of architects, engineers, and project managers, JSB Buildings ensures that client expectations are met on time and to a very high standard, providing turnkey solutions for residential projects of any scale."
                                        },
                                        {
                                            title: "What Expertise Does JSB Buildings Offer In Construction And Renovation?",
                                            content: "JSB Buildings specialises in a wide range of services, from simple renovations to residential apartment builds, supported by a strong subcontractor base in Sydney to ensure affordability and quality in every project."
                                        },
                                        {
                                            title: "How Does JSB Buildings Prioritise Safety And Client Satisfaction?",
                                            content: "At JSB Buildings, safety and client satisfaction are paramount. With a stringent focus on WH&S (Work Health & Safety) protocols and client needs, JSB ensures that every project is completed defect-free, with the client and workers' happiness as top priorities."
                                        }
                                    ].map((item, index) => (
                                        <div 
                                            key={index} 
                                            className={`item ${activeIndex === index ? 'active' : ''}`}
                                            onClick={() => toggleAccordion(index)}
                                        >
                                            <div className="accordion_tab">
                                                <h2 className="accordion_title">{item.title}</h2>
                                                <span className="accordion_tab_icon">
                                                    <i className={`open_icon ion-ios-arrow-${activeIndex === index ? 'up' : 'down'}`}></i>
                                                </span>
                                            </div>
                                            <div className="accordion_info">
                                                {item.content}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
            <Banner
                    title='Proud to be your trusted partner in residential construction in Sydney'
                    heading='Call Us'
                    phone='(02) 8723 - 9960'
                    bannerType='banner type_3'
                />
            <div className="video_section">
                <video 
                    autoPlay 
                    muted 
                    loop 
                    playsInline 
                    className="video_background"
                >
                    <source src="https://cdn.prod.website-files.com/65ed4e541d2d4ecefd4f72b5/661db8e4a0d3f997da3e1f0d_AdobeStock_84793487 (1)-transcode.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            
            <Testimonial01 pb='pd_btom_80' />
            <Blog />
            
            <ContactHome02 />
        </div>
    );
};

export default Home01;
