import React from 'react';
import ContactForm02 from '../../Components/ContactElements/ContactForm02';

const ContactHome02 = () => {
    return (
        <div className="contact">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12">
                        <div className="contact_us_1">
                            <div className="section_header">
                            <h3 style={{ color: 'white', fontSize: '17px' }}>Let's talk</h3>
                                <h4 className="section_title" style={{ color: 'white', fontSize: '30px' }}>
                                Contact one of our home advisors at (02) 8723 9960.
                                </h4>
                                <p style={{ color: 'white',fontSize: '17px' }}>
                                     Start your dream home journey with JSB Buildings today
                                </p>
                            </div>
                            <ContactForm02 />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="contact_side_image">
                            <img src="images/contac_img.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactHome02;