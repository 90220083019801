import React from 'react';

const ServiceCard01_02 = ({ data }) => {
    const { img, bg, title, description, serviceLink, detailsLink } = data;

    return (
        <div className="col-lg-4 col-md-6">
            <div className="iconbox">
                <div className="iconbox_wrapper">
                    <div className="iconbox_image">
                        <img src={bg} alt="img" />
                        <div className="iconbox_icon">
                            <a href={serviceLink}><img src={img} alt="icon" /></a> {/* Unique service link */}
                        </div>
                    </div>
                    <div className="iconbox_content">
                        <h3><a href={detailsLink}>{title}</a></h3> {/* Unique service details link */}
                        <p>{description}</p>
                        <div className="read_more">
                            <a href={detailsLink}><span>Learn More</span></a> {/* Unique service details link */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard01_02;
