import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import PageHeader from '../Components/common/PageHeader';
import CommunicationCard from '../Components/ContactElements/CommunicationCard';
import Map from '../Components/ContactElements/Map';
import Banner from '../Containers/Banner';

const ContactPage = () => {
    const navigate = useNavigate();  // Initialize useNavigate hook
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const ContactData = [
        {
            id: '1',
            iconClass: 'ion-ios-telephone-outline',
            infoBody01: 'Phone No',
            infoBody02: '(02) 8723 - 9960',
        },
        {
            id: '2',
            iconClass: 'fa fa-envelope-o',
            infoBody01: 'Email Address',
            infoBody02: 'info@jsbbuildings.com.au',
        },
        {
            id: '3',
            iconClass: 'fa fa-map-marker',
            infoBody01: 'Office Address',
            infoBody02: 'Office in Bass Hill, New South Wales',
            infoBodyClass: 'd-none',
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Using EmailJS to send the email
        emailjs.send(
            'service_f7ayhth', // Your EmailJS service ID
            'template_c5uiaje', // Your EmailJS template ID
            formData,
            'TsWKTP3_sN9q1lx_8'  // Your EmailJS public key
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            // Redirect to the Thank You page after successful submission
            navigate('/thankyou');
        }, (error) => {
            console.log('FAILED...', error);
        });
    };

    return (
        <>
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='Contact Us' heading='Contact Us' />
            <div className="main_wrapper">
                <div className="section">
                    <div className="container">
                        <div className="gmapbox">
                            <div id="googleMap" className="map">
                                <Map />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact_inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5">
                                <div className="keepintouch_3">
                                    {ContactData.map(data => (
                                        <CommunicationCard key={data.id} data={data} />
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 offset-lg-1">
                                <div className="contact_us_1">
                                    <div className="section_header">
                                        <h1 className="section_title">We are here to help you always...</h1>
                                    </div>
                                    <form className="contact_form" onSubmit={handleSubmit}>
                                        <div className="form-container">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input 
                                                            type="text" 
                                                            name="name" 
                                                            className="form-control" 
                                                            placeholder="Your Name*" 
                                                            required 
                                                            value={formData.name} 
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input 
                                                            type="email" 
                                                            name="email" 
                                                            className="form-control" 
                                                            placeholder="Email Address*" 
                                                            required 
                                                            value={formData.email} 
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input 
                                                            type="text" 
                                                            name="phone" 
                                                            className="form-control" 
                                                            placeholder="Phone No" 
                                                            value={formData.phone} 
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input 
                                                            type="text" 
                                                            name="subject" 
                                                            className="form-control" 
                                                            placeholder="Subject" 
                                                            value={formData.subject} 
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <textarea 
                                                            name="message" 
                                                            className="form-control" 
                                                            placeholder="Message Here*" 
                                                            required 
                                                            value={formData.message} 
                                                            onChange={handleChange}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <input className="button" type="submit" value="Submit" name="submit" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Banner
                    title='Proud to be your trusted partner in residential construction in Sydney'
                    heading='Give Us A Call'
                    phone='(02) 8723 - 9960'
                    bannerType='banner banner_bg_color'
                />
            </div>
        </>
    );
};

export default ContactPage;
