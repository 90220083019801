import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainer05 from '../../Containers/ServiceContainers/ServiceContainer05';

const ResidentialExtensions = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Residential Extensions' heading='Residential Extensions' />
            <ServiceContainer05 />
        </>
    );
};

export default ResidentialExtensions;