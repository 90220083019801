import React from 'react';
import { Controller, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TestimonialCard01 from '../../Components/TestimonialCards/TestimonialCard01';

const Testimonial01 = ({ pb }) => {
    const testimonialData = [
        {
            id: '1',
            img: 'images/reviewer1.png',
            name: 'Chris Morris',
            testimonial: 'JSB Buildings provided exceptional service, delivering my project on time and with incredible attention to detail. Their commitment to quality is truly outstanding.'
        },
        {
            id: '2',
            img: 'images/reviewer4.png',
            name: 'Marina Samuel',
            testimonial: 'Working with JSB Buildings was a pleasure. They exceeded expectations by delivering top-notch construction services and ensuring every detail was meticulously handled.'
        },
        {
            id: '3',
            img: 'images/reviewer3.png',
            name: 'Oakland Gardner',
            testimonial: 'Professionalism of JSB Buildings are unparalleled. They took my vision and turned it into reality, all while maintaining high standards and meeting deadlines.'
        },
    ];

    return (
        <div className={`testimonial ${pb}`}>
            <div className="container">
                <div className="section_header text-center">
                    <div className="shadow_icon">
                        <img src="images/ico.png" alt="Icon" />
                    </div>
                    <br />
                    <h1 className="section_title">What Our Clients Say About Us</h1>
                    <p className="section_desc">
                        We pride ourselves on our ability to deliver turnkey solutions tailored to meet the unique needs of each client.
                    </p>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Swiper
                            className="owl_testimonial1 owl-carousel owl-theme"
                            modules={[Pagination, Controller]}
                            effect="slide"
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                            }}
                            loop={true}
                            controller={{ inverse: true }}
                            spaceBetween={50}
                            pagination={{
                                el: '.testimonial-pagination',
                                clickable: true,
                            }}
                        >
                            {testimonialData.map((data) => (
                                <SwiperSlide key={data.id}>
                                    <TestimonialCard01 data={data} />
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        {/* Add Pagination 
                        
                            <div className="testimonial-pagination"></div>
                        */}
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial01;
