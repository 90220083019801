import $ from 'jquery';
import React, { useEffect } from 'react';
import PageHeader from '../Components/common/PageHeader';
import Banner from '../Containers/Banner';

const FAQ = () => {
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);


    return (
        <>
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='FAQ' heading='Frequently Asked Question' />

            <div className="main_wrapper">
                <div className="faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <div className="images-faq">
                                    <img src="images/man.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="section_header">
                                    <h6 className="section_sub_title">Frequently Asked Question</h6>
                                    <h1 className="section_title">Build better futures with focus on quality</h1>
                                </div>
                                <div className="accordion">
                                    <div className="item active">
                                        <div className="accordion_tab">
                                            <h2 className="accordion_title">What Sets JSB Buildings Apart In The Sydney Construction Market?</h2>
                                            <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                        </div>
                                        <div className="accordion_info">
                                        SB Buildings stands out as a leader in residential construction in Sydney, prioritising client satisfaction and community well-being in every project undertaken.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="accordion_tab">
                                            <h2 className="accordion_title">How Does JSB Buildings Ensure Quality And Efficiency In Its Projects?</h2>
                                            <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                        </div>
                                        <div className="accordion_info">
                                        With a dedicated team of architects, engineers, and project managers, JSB Buildings ensures that client expectations are met on time and to a very high standard, providing turnkey solutions for residential projects of any scale.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="accordion_tab">
                                            <h2 className="accordion_title">What Expertise Does JSB Buildings Offer In Construction And Renovation?</h2>
                                            <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                        </div>
                                        <div className="accordion_info">
                                        JSB Buildings specialises in a wide range of services, from simple renovations to residential apartment builds, supported by a strong subcontractor base in Sydney to ensure affordability and quality in every project.
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="accordion_tab">
                                            <h2 className="accordion_title">How Does JSB Buildings Prioritise Safety And Client Satisfaction?</h2>
                                            <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                        </div>
                                        <div className="accordion_info">
                                        At JSB Buildings, safety and client satisfaction are paramount. With a stringent focus on WH&S (Work Health & Safety) protocols and client needs, JSB ensures that every project is completed defect-free, with the client and workers' happiness as top priorities.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Banner
                    title='Proud to be your trusted partner in residential construction in Sydney'
                    heading='Give Us A Call'
                    phone='(02) 8723 9960'
                    bannerType='banner banner_bg_color'
                />

            </div>
        </>
    );
};

export default FAQ;