import React from 'react';
import BannerText from '../Components/BannerElements/BannerText';

const Banner = (props) => {

    return (
        <div className={props.bannerType}>
            <div className="container">
                <div className="banner_content">
                    <div className="row">
                        <div className="col-lg-8">
                            <BannerText title={props.title} />
                        </div>
                        <div className="col-lg-4">
                            <div className="banner_phone" style={styles.bannerPhone}>
                                <h2 style={styles.heading}>{props.heading}</h2>
                                <a href="tel:+61287239960" className="phone-link" style={styles.phoneLink}>
                                    (02) 8723 - 9960
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    bannerPhone: {
        textAlign: '',
    },
    heading: {
        color: '#fff', // White color
        fontSize: '30px', // Increase font size
        marginBottom: '10px',
    },
    phoneLink: {
        color: '#fff', // White color
        fontSize: '35px', // Increase font size
        textDecoration: 'none',
        fontWeight: 'bold',
    },
};

export default Banner;
