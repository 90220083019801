import React from 'react';

const TestimonialCard02 = ({ data }) => {
    const { name, upperImg, midImg } = data;
    return (
        <div className="item">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="testimonial_image">
                                <img className="first_img" src="images/others/Ellipse_55.png" alt="" />
                                <div className="image_color">
                                    <img className="middle_img" src={midImg} alt="" />
                                </div>
                                <img className="last_img" src="images/others/Ellipse_56.png" alt="" />
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-5">
                            <div className="testibox2">
                                <div className="testibox_inner">
                                    <div className="testi-content testibox_others">
                                        <div className="upper_img">
                                            <img src={upperImg} alt="" />
                                        </div>
                                        <div style={{ padding: '50px', backgroundColor: '#f8f9fa', textAlign: 'left', lineHeight: '1.6' }}>
            <h2 style={{ 
                color: '#000', 
                fontSize: '20px', 
                fontWeight: '', 
                marginBottom: '20px' 
            }}>
                "From design to build to approvals, we provide comprehensive services to take the stress out of the construction process"
            </h2>
            <p style={{ 
                fontSize: '16px', 
                color: '#555', 
                lineHeight: '1.6', 
                marginBottom: '20px' 
            }}>
                Our expertise extends to house renovations, where we specialise in taking the difficulty out of remodelling, ensuring a seamless and hassle-free experience for our clients. With engineers at the ready, we ensure that every project is structurally sound and built to regulation, prioritising stability and safety above all else.
            </p>
        </div>
                                    </div>
                                    <div className="testi-top">
                                        <div className="testi-info_others">
                                            <h4>{name}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard02;