import React from 'react';
import Video from '../../Components/BannerElements/Video';
import Testimonial01 from '../Testimonials/Testimonial01';

const ServiceContainer06 = () => {
    return (
        <div className="main_wrapper">
            <div className="container">
                <div className="project_details section">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="project_details_inner">
                                <div className="post_img">
                                    <img src="images/services/granny.png" alt="blog" />
                                </div>
                                <div className="post_content">
                                    <div className="post_header">
                                        <h3 className="post_title">JSB Building Services</h3>
                                    </div>
                                    <div className="fulltext">
                                        <p>
                                            JSB Buildings takes pride in being your premier choice for Granny Flats construction in Sydney. Gone are the days when Granny Flats were solely for grandmothers; we specialise in modern and expansive designs that cater to various needs and lifestyles across NSW.
                                        </p>
                                        <div className="post_header">
                                        <h3 className="post_title">Features</h3>
                                    </div>
                                        <ol>
                                                <li><strong>Modern and Expansive Designs:</strong>  At JSB Buildings, we offer modern and spacious Granny Flat designs that are suitable for a range of purposes, from extra accommodation to teenage retreats.</li>
                                                <li><strong>Flexible Upgrade Options:</strong>  Customize your Granny Flat with a range of upgrade options to suit your specific needs and requirements. Whether it's adding extra bedrooms or luxury fit-outs, we can tailor the design to your preferences.</li>
                                                <li><strong>Affordable Prices:</strong> Our Granny Flat prices are incredibly affordable, ensuring that you get exceptional value without compromising on quality. We offer both standard and luxury fit-outs to accommodate different budgetary requirements.</li>
                                                <li><strong>Comprehensive Service:</strong>  From design to construction, we provide comprehensive and integrated Granny Flat construction services. We handle everything, including connecting services, to make the process as seamless as possible for you.</li>
                                                <li><strong>Customer Involvement:</strong>  At JSB Buildings, we keep you involved throughout the entire process, ensuring that your requests are fully met. Your satisfaction is our priority, and we strive to deliver results that exceed your expectations.</li>
                                                <li><strong>Registered Building Practitioner:</strong>  We are a registered building practitioner, committed to providing top-quality Granny Flat units that meet industry standards and regulations.</li>
                                        </ol>

                                        <p>
                                        Contact JSB Buildings today for more information on our Granny Flat construction services in Sydney and NSW. Whether you need a one, two, or three-bedroom flat, we have the expertise and flexibility to fulfill your requirements and deliver a custom-made Granny Flat that meets your needs.
                                        </p>

                                        <h4 className="widget_title">
                                        Our Working Process

                                            <span className="title_line"></span>
                                        </h4>
                                        <p className="margin_o_para">We possess a proven track record of successfully completing extensive extension and expansion projects for a variety of building types, including schools, leisure centers, churches, and industrial units. Our projects have involved creating additional space and introducing new facilities to cater to the evolving needs of our clients. We are experienced in operating in close proximity to occupied buildings, ensuring minimal disruption while maximizing efficiency and safety throughout the construction process.</p>
                                            <br />
                                        <Video bg='images/services/video_bg.png' />

                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="sidebar">
                                <div id="widgetnav" className="widget widget_menu">
                                    <div className="sidenav">
                                    <ul className="side_menu">
                                            <li className="menu-item active">
                                                <a href="/newbuild">
                                                    <img src="images/services/cone_1.png" alt="" />
                                                    <img src="images/services/2-white.png" alt="" />
                                                    Residential New Build
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/grannyflat">
                                                    <img src="images/services/9-blue.png" alt="" />
                                                    <img src="images/services/cone_2.png" alt="" />
                                                    Residential Granny Flat
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/residentialextensions">
                                                    <img src="images/services/cone_3.png" alt="" />
                                                    <img src="images/services/5-blue.png" alt="" />
                                                    Residential Extensions
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/residentialrenovations">
                                                    <img src="images/services/cone_4.png" alt="" />
                                                    <img src="images/services/1-white.png" alt="" />
                                                    
                                                    Residential Renovations
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="custom_2" className="widget widget_side_contact">
                                    <div className="side_contact">
                                        <div className="side_contact_top">
                                            <div className="side_contact_inner">
                                                <p>LET’S WORK TOGETHER
                                                </p>
                                                <h4>Info@jsbbuildings.com.au
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="side_phone_inner">
                                            <div>(02) 8723 9960
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial01 pb='pd_btom_80 pd_top_80' />
            
            
        </div>
        
    );
};

export default ServiceContainer06;