import React from 'react';
import BlogCards01 from '../../Components/BlogCards/BlogCards01';
import PageHeader from '../../Components/common/PageHeader';
import Pagination from '../../Components/common/Pagination';

const Blog01 = () => {
    const BlogData01 = [
        {
            id: '1',
            img: 'images/blog/b6.png',
            blogClass: 'col-lg-4 col-md-6',
            title: 'Pioneering Success: JSB Buildings Sets New Standards',
            description: 'JSB Buildings continues to pioneer new methods in construction, earning recognition for its commitment to excellence and growth…'
        },
        {
            id: '2',
            img: 'images/blog/b7.png',
            blogClass: 'col-lg-4 col-md-6',
            title: 'Innovation Through Diversity at JSB Buildings',
            description: 'JSB Buildings leverages the talents of 10,000 individuals from 90 different companies to bring fresh perspectives to every project…'
        },
        

    ];
    return (
        <div className="main_wrapper">
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='Blog' heading='Recent Updates' />
            <div className="blog section">
                <div className="container">
                    <div className="blog_grid">
                        <div className="row">
                            {
                                BlogData01.map(data => <BlogCards01
                                    key={data.id}
                                    data={data}
                                />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog01;