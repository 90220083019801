import React from 'react';
import Video from '../../Components/BannerElements/Video';
import Testimonial01 from '../Testimonials/Testimonial01';

const ServiceContainer07 = () => {
    return (
        <div className="main_wrapper">
            <div className="container">
                <div className="project_details section">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="project_details_inner">
                                <div className="post_img">
                                    <img src="images/services/vation.png" alt="blog" />
                                </div>
                                <div className="post_content">
                                    <div className="post_header">
                                        <h3 className="post_title">JSB Building Services</h3>
                                    </div>
                                    <div className="fulltext">
                                        <p>
                                        JSB Buildings is your premier choice for residential renovations in Melbourne, Victoria. With our expertise and commitment to excellence, we transform homes into modern and functional living spaces that cater to your unique needs and lifestyle.
                                        </p>
                                        <div className="post_header">
                                        <h3 className="post_title">Features</h3>
                                    </div>
                                        <ol>
                                                <li><strong>Modern and Expansive Designs:</strong> At JSB Buildings, we offer modern and spacious renovation designs tailored to your preferences. Whether you're looking to update your kitchen, bathroom, or entire home, we create spaces that enhance comfort and functionality.</li>
                                                <li><strong>Flexible Upgrade Options:</strong> Customize your renovation with a range of upgrade options to suit your specific requirements. From adding extra rooms to incorporating luxury features, we ensure that your renovation reflects your personal style and preferences.</li>
                                                <li><strong>Affordable Prices:</strong>Our renovation prices are incredibly competitive, providing exceptional value without compromising on quality. We work within your budget to deliver results that exceed your expectations.</li>
                                                <li><strong>Comprehensive Service:</strong>From design conception to project completion, we provide comprehensive and integrated renovation services. Our team handles every aspect of the renovation process, ensuring a seamless and stress-free experience for you.</li>
                                                <li><strong>Customer Involvement:</strong> At JSB Buildings, we prioritize customer involvement throughout the entire renovation journey. We listen to your ideas and preferences, keeping you informed and involved at every stage to ensure your complete satisfaction.</li>
                                                <li><strong>Registered Building Practitioner:</strong> SB Buildings is a registered building practitioner, adhering to industry standards and regulations to deliver top-quality renovation projects that stand the test of time.</li>
                                        </ol>

                                        <p>
                                            Contact JSB Buildings today for more information on our residential renovation services in Melbourne and Victoria. Whether you're considering a minor update or a complete overhaul, trust JSB Buildings to bring your vision to life with professionalism and expertise.
                                        </p>

                                        <h4 className="widget_title">
                                        Our Working Process

                                            <span className="title_line"></span>
                                        </h4>
                                        <p className="margin_o_para">We possess a proven track record of successfully completing extensive extension and expansion projects for a variety of building types, including schools, leisure centers, churches, and industrial units. Our projects have involved creating additional space and introducing new facilities to cater to the evolving needs of our clients. We are experienced in operating in close proximity to occupied buildings, ensuring minimal disruption while maximizing efficiency and safety throughout the construction process.</p>
                                            <br />
                                        <Video bg='images/services/video_bg.png' />

                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="sidebar">
                                <div id="widgetnav" className="widget widget_menu">
                                    <div className="sidenav">
                                        <ul className="side_menu">
                                            <li className="menu-item active">
                                                <a href="/newbuild">
                                                    <img src="images/services/cone_1.png" alt="" />
                                                    <img src="images/services/2-white.png" alt="" />
                                                    Residential New Build
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/grannyflat">
                                                    <img src="images/services/9-blue.png" alt="" />
                                                    <img src="images/services/cone_2.png" alt="" />
                                                    Residential Granny Flat
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/residentialextensions">
                                                    <img src="images/services/cone_3.png" alt="" />
                                                    <img src="images/services/5-blue.png" alt="" />
                                                    Residential Extensions
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/residentialrenovations">
                                                    <img src="images/services/cone_4.png" alt="" />
                                                    <img src="images/services/1-white.png" alt="" />
                                                    
                                                    Residential Renovations
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="custom_2" className="widget widget_side_contact">
                                    <div className="side_contact">
                                        <div className="side_contact_top">
                                            <div className="side_contact_inner">
                                                <p>LET’S WORK TOGETHER
                                                </p>
                                                <h4>Info@jsbbuildings.com.au
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="side_phone_inner">
                                            <div>(02) 8723 9960
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial01 pb='pd_btom_80 pd_top_80' />
            
            
        </div>
        
    );
};

export default ServiceContainer07;