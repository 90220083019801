import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import Partners02 from '../Components/Partners/Partners02';
import TopFeatures from '../Components/TopFeatures';
import Banner from '../Containers/Banner';
import ExperienceAbout from '../Containers/Experiences/ExperienceAbout';
import Experience02 from '../Containers/Experiences/Experience02';
import FunFactAbout from '../Containers/Funfacts/FunFactAbout';
import Team from '../Containers/Team';
import Testimonial01 from '../Containers/Testimonials/Testimonial01';
import ContactHome02 from '../Containers/Contacts/ContactHome02';
import Testimonial02 from '../Containers/Testimonials/Testimonial02';

const About = () => {
    const AboutBannerData = {
        title: 'Builderrine is proud to serve you 24/7. Just Call Us when you need',
        heading: 'Call Us Anytime',
        phone: '+99 (1234) 56 789'
    };

    return (
        <>
            <PageHeader activeClass01="active" activeClass02="d-none" routeName01="About Us" heading="About Company" />

            <div className="main_wrapper">
                <br /><br />

                <ExperienceAbout />
                <div style={{ marginTop: '-130px', marginBottom: '-120px' }}>
                    <FunFactAbout />
                </div>
                

                {/* New Content Section */}
                <div style={{ padding: '40px 20px', backgroundColor: '#fff' }}>
                    <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                        <p style={{ fontSize: '18px', color: '#333', maxWidth: '800px', margin: '0 auto' }}>
                            JSB Buildings is not just a construction company; we're your partners in turning dreams into reality. We invite you to explore our past projects and envision the possibilities for your own home. Contact us today to embark on your journey with JSB Buildings and experience the difference that passion, expertise, and dedication can make. Remember, at JSB Buildings, we offer a wide range of services to cater to your every need:
                        </p>
                    </div>
                    <div style={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        gap: '20px', 
                        flexWrap: 'wrap', 
                        margin: '0 auto', 
                        maxWidth: '800px' 
                    }}>
                        <div style={{ 
                            flex: '1 1 300px', 
                            border: '1px solid #ccc', 
                            padding: '20px', 
                            borderRadius: '8px', 
                            backgroundColor: '#f9f9f9', 
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                        }}>
                            <ul style={{ 
                                listStyleType: 'none', 
                                paddingLeft: '0', 
                                fontSize: '16px', 
                                color: '#555' 
                            }}>
                                <li>- House Renovations</li>
                                <li>- Engineering Services</li>
                                <li>- Project Management</li>
                                <li>- New Construction</li>
                                <li>- Approval Acquisition</li>
                                <li>- Safety Compliance</li>
                                <li>- Consultation and Advice</li>
                            </ul>
                        </div>
                        <div style={{ 
                            flex: '1 1 300px', 
                            border: '1px solid #ccc', 
                            padding: '20px', 
                            borderRadius: '8px', 
                            backgroundColor: '#f9f9f9', 
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                        }}>
                            <ul style={{ 
                                listStyleType: 'none', 
                                paddingLeft: '0', 
                                fontSize: '16px', 
                                color: '#555' 
                            }}>
                                <li>- Construction Design</li>
                                <li>- Renovations</li>
                                <li>- Turnkey Solutions</li>
                                <li>- Interior Fitouts</li>
                                <li>- Residential New Builds</li>
                                <li>- Renovation Solutions</li>
                                <li>- Architectural Design</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Experience02 />
                <Testimonial02 />
                
                <ContactHome02 />
                <Testimonial01 pb="pd_btom_110" />
                <Banner
                    title='Proud to be your trusted partner in residential construction in Sydney'
                    heading='Give Us A Call'
                    phone='(02) 8723 - 9960'
                    bannerType='banner banner_bg_color'
                />
            </div>
        </>
    );
};

export default About;
