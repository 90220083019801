import React from 'react';
import Video from '../../Components/BannerElements/Video';
import PageHeader from '../../Components/common/PageHeader';

const BlogDetails = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Blog' heading='Pioneering Success' />

            <div className="main_wrapper section">
                <div className="container">
                    <div className="blog_details">
                        <div className="post_img">
                            <img src="images/blog/3.png" alt="blog" />
                            <div className="calendar">
                                <a href="#"><span className="date">30</span><br />May</a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-12 pe-4">
                                <div className="blog_details_inner">
                                    <div className="post_content">
                                        <div className="post_header">
                                            <div className="categ"><a href="/about">CONSTRUCTION</a></div>
                                            <h3 className="post_title">Pioneering Success: JSB Buildings Sets New Standards</h3>
                                        </div>
                                        <div className="fulltext">

                                            <p>
                                            JSB Buildings has emerged as a trailblazer in the construction industry, consistently setting new benchmarks through innovation and an unwavering commitment to quality. Their approach to each project is characterized by the integration of cutting-edge technology and meticulous attention to detail, ensuring that every build not only meets but surpasses industry standards. By fostering strong partnerships and collaborating with top-tier professionals, JSB Buildings has created a reputation for delivering results that are both durable and aesthetically exceptional.
                                            </p>

                                           

                                            <p>
                                            What sets JSB Buildings apart is their relentless pursuit of excellence and their ability to adapt to the evolving demands of the construction landscape. They continuously push the boundaries of what’s possible, pioneering new methods that redefine the industry. This commitment to setting higher standards has positioned JSB Buildings as a leader in both residential and commercial construction, earning them recognition and trust across the sector. Their success story is a testament to their dedication to quality, innovation, and customer satisfaction.
                                            </p>
                                           

                                           
                                        </div>

                                        
                                    </div>

                                    <div className="border_line"></div>

                                    

                                    <div className="border_line"></div>

                                    

                                    
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogDetails;