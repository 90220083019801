import React from 'react';

function Map() {
    return (
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26492.60845637077!2d150.9724866403041!3d-33.90057066514781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bdcecc98053d%3A0x5017d681632ad40!2sBass%20Hill%20NSW%202197%2C%20Australia!5e0!3m2!1sen!2szw!4v1727127195807!5m2!1sen!2szw"
            width="100%"  // Adjusting the width to fit the container
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Bass Hill, NSW Map"
        ></iframe>
    );
}

function MapContainer() {
    return (
        <div className="gmapbox">
            <div id="googleMap" className="map">
                <Map />
            </div>
        </div>
    );
}

export default MapContainer;
