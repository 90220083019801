import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import emailjs from '@emailjs/browser';


const ContactForm02 = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    service: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validate = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!formData.service) {
      errors.service = 'Please select a service';
    }

    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // Send email using EmailJS
      emailjs.send(
        'service_f7ayhth', // Your EmailJS Service ID
        'template_uybbq1n', // Your EmailJS Template ID
        formData, // The data to send
        'TsWKTP3_sN9q1lx_8' // Your EmailJS User ID (replace this)
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email sent successfully!');
        // Redirect to the Thank You page after successful form submission
        navigate('/thankyou');
      })
      .catch((err) => {
        console.log('FAILED...', err);
        alert('Failed to send email');
      });

      // Reset form fields
      setFormData({
        name: '',
        email: '',
        service: '',
        message: ''
      });
    }
  };

  return (
    <form className="contact_form" onSubmit={handleSubmit}>
      <div className="form-container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Your Name*"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address*"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <select
                className="form-select"
                aria-label="Default select example"
                name="service"
                value={formData.service}
                onChange={handleChange}
                required
              >
                <option value="">Choose Service</option>
                <option value="Residential Renovations">Residential Renovations</option>
                <option value="Residential Granny Flat">Residential Granny Flat</option>
                <option value="Residential Extensions">Residential Extensions</option>
                <option value="Residential New Build">Residential New Build</option>
              </select>
              {errors.service && <p className="error">{errors.service}</p>}
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <textarea
                name="message"
                className="form-control"
                placeholder="Details...."
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              {errors.message && <p className="error">{errors.message}</p>}
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <input
                className="button"
                type="submit"
                value="Submit"
                name="submit"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm02;
