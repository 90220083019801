import React from 'react';
import Video from '../../Components/BannerElements/Video';
import PageHeader from '../../Components/common/PageHeader';

const BlogDetails = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Blog' heading='Innovation Through Diversity' />

            <div className="main_wrapper section">
                <div className="container">
                    <div className="blog_details">
                        <div className="post_img">
                            <img src="images/blog/3.png" alt="blog" />
                            <div className="calendar">
                                <a href="#"><span className="date">30</span><br />May</a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-12 pe-4">
                                <div className="blog_details_inner">
                                    <div className="post_content">
                                        <div className="post_header">
                                            <div className="categ"><a href="/about">CONSTRUCTION</a></div>
                                            <h3 className="post_title">Innovation Through Diversity at JSB Buildings</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                At JSB Buildings, diversity isn’t just a buzzword—it’s a driving force behind the company's continued innovation and success. By embracing the unique talents, perspectives, and backgrounds of over 10,000 professionals across 90 different companies, JSB Buildings has cultivated an environment where creativity thrives and breakthroughs are made possible. The company firmly believes that innovation is born from collaboration, and the diversity within its teams allows them to approach challenges with fresh eyes and think beyond traditional solutions.
                                            </p>
                                            <p>
                                                This dynamic approach has enabled JSB Buildings to stay ahead of industry trends, continually developing cutting-edge solutions that set new benchmarks in the construction sector. Whether it’s the design of sustainable, eco-friendly buildings or the application of advanced construction technologies, the diverse input from their workforce has been a key differentiator. The collective expertise of their diverse team has not only led to technical advancements but has also fostered an inclusive culture where every voice is valued.
                                            </p>
                                            <p>
                                                Diversity at JSB Buildings extends beyond the workforce. It’s reflected in the wide array of projects they take on, from residential housing developments to large-scale commercial complexes. Each project is approached with the understanding that no two clients are the same, and thus, no two solutions should be identical. This level of customization and attention to detail is only possible because of the wide-ranging perspectives their diverse teams bring to the table.
                                            </p>
                                            <p>
                                                Moreover, JSB Buildings continues to invest in diversity by promoting inclusion at all levels of the organization. By offering continuous training, mentorship programs, and opportunities for professional development, they ensure that their workforce remains adaptable, innovative, and poised for growth. As a result, JSB Buildings isn’t just leading in terms of innovation—they’re setting a powerful example of how diversity can fuel long-term success in the construction industry.
                                            </p>
                                            <p>
                                                In a rapidly evolving world, JSB Buildings stands as a testament to the power of diversity and its undeniable link to innovation. Their success story highlights the importance of creating spaces where different perspectives can flourish and how that, in turn, drives progress not only for the company but for the industry as a whole.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="border_line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Fix the export to use BlogDetails, not Innovation
export default BlogDetails;
