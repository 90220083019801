import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileNav from './MobileNav/MobileNav';

const Header02 = () => {
    const [isActive, setActive] = useState(false);
    const handleToggle = () => {
        setActive(!isActive);
    };

    return (
        <>
            <header className="header header_color">
                <div className="middle_bar">
                    <div className="container">
                        <div className="middle_bar_inner">
                            <div className="logo">
                                <Link to="/" className="light_mode_logo"><img src="images/JSB-blue.svg" alt="logo" /></Link>
                                <Link to="/" className="dark_mode_logo"><img src="images/JSB-white.svg" alt="logo" /></Link>
                            </div>

                            <div className="header_right_part">
                                <div className="mainnav">
                                    <ul className="main_menu">
                                        <li className="menu-item menu-item-has-children active"><Link to="/">Home</Link>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><Link to="/">Home One</Link></li>
                                                <li className="menu-item active"><Link to="/home-2">Home Two</Link></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children"><Link to="#">Services</Link>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><Link to="/services">Service One</Link></li>
                                                <li className="menu-item"><Link to="/services-2">Service Two</Link></li>
                                                <li className="menu-item"><Link to="/service-details">Service Details</Link></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children"><Link to="#">Projects</Link>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><Link to="/project">Projects One</Link></li>
                                                <li className="menu-item"><Link to="/project-2">Projects Two</Link></li>
                                                <li className="menu-item"><Link to="/project-details">Projects Details</Link></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children"><Link to="#">Pages</Link>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><Link to="/about">About</Link></li>
                                                <li className="menu-item"><Link to="/team">Team</Link></li>
                                                <li className="menu-item"><Link to="/faq">FAQ</Link></li>
                                                <li className="menu-item menu-item-has-children"><Link to="#">Shop</Link>
                                                    <ul className="sub-menu">
                                                        <li className="menu-item"><Link to="/shop-1">Shop One</Link></li>
                                                        <li className="menu-item"><Link to="/shop-2">Shop Two</Link></li>
                                                        <li className="menu-item"><Link to="/shop-3">Shop Three</Link></li>
                                                        <li className="menu-item"><Link to="/product">Product Details</Link></li>
                                                        <li className="menu-item"><Link to="/cart">Cart</Link></li>
                                                        <li className="menu-item"><Link to="/checkout">Checkout</Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children"><Link to="#">Blog</Link>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><Link to="/blog-1">Blog One</Link></li>
                                                <li className="menu-item"><Link to="/blog-2">Blog Two</Link></li>
                                                <li className="menu-item"><Link to="/blog-details">Blog Details</Link></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item"><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                                <div className="phone phn_color">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <div><span>Call Us</span><br /><span className="phn_number">(02) 8723 9960</span></div>
                                </div>
                                <div className="header_search" onClick={handleToggle}>
                                    <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                </div>
                                <div id='open_search' className={isActive ? null : "active"}>
                                    <form className="search_form" action="search.php">
                                        <input type="text" name="search" className="keyword form-control" placeholder="Search..." />
                                        <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                    </form>
                                </div>
                                <button className="ma5menu__toggle position-relative d-xl-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasHome" aria-controls="offcanvasHome">
                                    <i className="ion-android-menu"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MobileNav />
        </>
    );
};

export default Header02;
