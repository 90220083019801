import React, { useState } from 'react';
import MobileNav from './MobileNav/MobileNav';

const Header = () => {
    // Hide header on scroll down
    window.onscroll = function () { myFunction() }; function myFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector('header').classList.add('dark_mode');
            document.querySelector('header .top_bar').classList.add('top-up');
        } else {
            document.querySelector('header').classList.remove('dark_mode');
            document.querySelector('header .top_bar').classList.remove('top-up');
        }
    }

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };

    return (
        <>
            <header className="header">
                {/* <PreHeader /> */}

                <div className="top_bar" id='top-bar'>
                    <div className="container">
                        <div className="top_bar_inner">
                            <div className="header_social">
                                <h6>Follow Us</h6>
                                <ul className="top_social">
                                    <li className="facebook"><a href="https://www.facebook.com/jsbbuildings"><i className="ion-social-facebook"></i></a></li>
                                    <li className="twitter"><a href="https://www.facebook.com/jsbbuildings"><i className="ion-social-twitter"></i></a></li>
                                    <li className="instagram"><a href="https://www.facebook.com/jsbbuildings"><i className="ion-social-instagram-outline"></i></a></li>
                                    <li className="linkedin"><a href="https://www.facebook.com/jsbbuildings"><i className="ion-social-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <div className="header_info">
                                <div className="schedule">
                                    <div className="hide_icon d-md-none" data-text="Office in Bass Hill , New South Wales"> <span><i className="fa fa-clock-o"></i></span></div>
                                    <div className="d-none d-md-block"><span><i className="fa fa-map"></i></span> Office in Bass Hill , New South Wales</div>
                                </div>
                                <div className="schedule">
                                    <div className="hide_icon d-md-none" data-text="info@jsbbuildings.Com.au"> <span><i className="fa fa-envelope"></i></span></div>
                                    <div className="d-none d-md-block"><span><i className="fa fa-envelope"></i></span> info@jsbbuildings.com.au</div>
                                </div>
                                <div className="free_contact">
                                    <a href="/contact" className="btn">Get A Quote</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle_bar">
                    <div className="container">
                        <div className="middle_bar_inner">
                            <div className="logo">
                                <a href="/" className="light_mode_logo"><img src="images/JSB-blue.svg" alt="logo" /></a>
                                <a href="/" className="dark_mode_logo"><img src="images/JSB-blue.svg" alt="logo" /></a>
                            </div>

                            <div className="header_right_part">
                                <div className="mainnav">
                                    <ul className="main_menu">
                                        <li className="menu-item active"><a href="/">Home</a>
                                            
                                        </li>
                                        <li className="menu-item"><a href="/about">Why JSB</a></li>
                                        <li className="menu-item menu-item-has-children"><a href="#">Services</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><a href="/newbuild">Residential New Build</a></li>
                                                <li className="menu-item"><a href="/grannyflat">Residential Granny Flat</a></li>
                                                <li className="menu-item"><a href="/residentialextensions">Residential Extensions</a></li>
                                                <li className="menu-item"><a href="/residentialrenovations">Residential Renovations</a></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item"><a href="/faq">FAQ</a></li>
                                        <li className="menu-item"><a href="/contact">Contact</a></li>
                                    </ul>
                                </div>
                                <div className="phone">
                                    <a href="tel:0287239960"><i className="fa fa-phone" aria-hidden="true"></i></a>
                                    
                                    <a href="tel:0287239960">
                                    <div><span>Call Us</span><br /><span className="phn_number">(02) 8723 9960</span></div>
                                    </a>
                                </div>
                                <div className='header_search' onClick={handleToggle}>
                                    <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                </div>
                                <div id='open_search' className={isActive ? null : "active"}>
                                    <form className="search_form" action="search.php">
                                        <input type="text" name="search" className="keyword form-control" placeholder="Search..." />
                                        <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                    </form>
                                </div>

                                <button className="ma5menu__toggle position-relative d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasHome" aria-controls="offcanvasHome"
                                >
                                    <i className="ion-android-menu"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <MobileNav />

        </>
    );
};

export default Header;