import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainer04 from '../../Containers/ServiceContainers/ServiceContainer04';

const NewBuild = () => {
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Residential New Build' heading='Residential New Build' />
            <ServiceContainer04 />
        </>
    );
};

export default NewBuild;