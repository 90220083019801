import React from 'react';
import Video from '../../Components/BannerElements/Video';
import Testimonial01 from '../Testimonials/Testimonial01';

const ServiceContainer03 = () => {
    return (
        <div className="main_wrapper">
            <div className="container">
                <div className="project_details section">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="project_details_inner">
                                <div className="post_img">
                                    <img src="images/services/new-builds.jpg" alt="blog" />
                                </div>
                                <div className="post_content">
                                    <div className="post_header">
                                        <h3 className="post_title">JSB Building Services</h3>
                                    </div>
                                    <div className="fulltext">
                                        <p>
                                            JSB Building is the premier provider of residential new builds in Sydney, offering a comprehensive suite of services tailored to meet the diverse needs of homeowners. Our seasoned team specializes in crafting high-quality living spaces, including single-family houses, townhouses, and condominiums, meticulously designed to exceed client expectations.

                                            With JSB Building, clients benefit from a seamless, integrated approach to construction, from initial concept to final completion. Our commitment to collaboration ensures that every project is executed with precision and efficiency, with close coordination among designers, architects, engineers, and construction professionals.

                                            Our array of services includes:
                                        </p>
                                        <ol>
                                                <li><strong>Full Principal Contractor Service:</strong> Entrust the entire project to our capable hands, ensuring a streamlined and efficient construction process.</li>
                                                <li><strong>Full-time Onsite Supervision:</strong> Our dedicated team provides vigilant oversight, ensuring that every detail adheres to the highest standards of quality.</li>
                                                <li><strong>Groundworks and Foundations:</strong> Lay the groundwork for a solid foundation, essential for the longevity and stability of your new home.</li>
                                                <li><strong>Timber and Steel Frame Construction:</strong> Benefit from our expertise in traditional timber and modern steel construction methods, delivering durable and aesthetically pleasing structures.</li>
                                                <li><strong>Mechanical & Electrical Installations:</strong> Rely on our proficiency in installing state-of-the-art mechanical and electrical systems, ensuring optimal functionality and comfort.</li>
                                                <li><strong>External Landscaping, Car Parks, and Paths:</strong> Enhance the curb appeal and functionality of your property with our comprehensive landscaping services, including car park construction and pathway installation.</li>
                                        </ol>

                                        <p>
                                            At JSB Building, we pride ourselves on our professionalism and commitment to excellence. Our transparent and transactional approach ensures clear communication and accountability throughout the entire project lifecycle. Clients can rest assured that their vision will be realized with precision and care, making JSB Building the partner of choice for discerning homeowners seeking top-tier residential construction solutions in Sydney.
                                        </p>

                                        <h4 className="widget_title">
                                        Our Working Process

                                            <span className="title_line"></span>
                                        </h4>
                                        <p className="margin_o_para">We possess a proven track record of successfully completing extensive extension and expansion projects for a variety of building types, including schools, leisure centers, churches, and industrial units. Our projects have involved creating additional space and introducing new facilities to cater to the evolving needs of our clients. We are experienced in operating in close proximity to occupied buildings, ensuring minimal disruption while maximizing efficiency and safety throughout the construction process.</p>
                                            <br />
                                        <Video bg='images/services/video_bg.png' />

                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="sidebar">
                                <div id="widgetnav" className="widget widget_menu">
                                    <div className="sidenav">
                                        <ul className="side_menu">
                                            <li className="menu-item active">
                                                <a href="/services">
                                                    <img src="images/services/cone_1.png" alt="" />
                                                    <img src="images/services/2-white.png" alt="" />
                                                    Residential New Build
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services-2">
                                                    <img src="images/services/9-blue.png" alt="" />
                                                    <img src="images/services/cone_2.png" alt="" />
                                                    Residential Granny Flat
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services">
                                                    <img src="images/services/cone_3.png" alt="" />
                                                    <img src="images/services/5-blue.png" alt="" />
                                                    Residential Extensions
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="/services-2">
                                                    <img src="images/services/cone_4.png" alt="" />
                                                    <img src="images/services/1-white.png" alt="" />
                                                    
                                                    Residential Renovations
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="custom_2" className="widget widget_side_contact">
                                    <div className="side_contact">
                                        <div className="side_contact_top">
                                            <div className="side_contact_inner">
                                                <p>LET’S WORK TOGETHER
                                                </p>
                                                <h4>Info@jsbbuildings.com.au
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="side_phone_inner">
                                            <div>(02) 8723 9960
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonial01 pb='pd_btom_80 pd_top_80' />
        </div>
    );
};

export default ServiceContainer03;