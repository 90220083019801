import React from 'react';
import AboutBelowContent from '../../Components/ExperienceBlocks/AboutBelowContent';

const Experience = () => {
    const headerData = {
        img: 'images/about/ico.png',
        heading: 'We Deliver Unique Construction Solutions',
        subheading: 'About Us',
    };

    const ExpericenData = [
        {
            id: '1',
            title: 'Residential New Builds',

        },
        {
            id: '2',
            title: 'House Extentions',
        },
        {
            id: '2',
            title: 'House Renovations',
        },
    ];

    return (
        <div className="experience section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <h1>10</h1>
                                    Years of Experience
                                </div>
                            </div>
                            <div className="image_object">
                                <img src="images/about/cap.png" alt="" />
                                <div className="object">                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content">
                            <div className="section_header">
                                <div className="shadow_icon"><img src={headerData.img} alt="" /></div>
                                <h6 className="section_sub_title">{headerData.subheading}</h6>
                                <h1 className="section_title">{headerData.heading}</h1>
                                <p className="section_desc">JSB Buildings is at the forefront of residential construction 
                                    in the Sydney Market. Choosing JSB Buildings for your next building or renovation 
                                    project will not just keep you happy but it will keep the whole community happy. Contact us 
                                    today to embark on your journey with JSB Buildings and experience the difference that passion, 
                                    expertise, and dedication can make.And remember, at 
                                    JSB Buildings, we offer a wide range of services to cater to your every need</p>
                                <div className="about_below">
                                    {
                                        ExpericenData.map(data =>
                                            <AboutBelowContent
                                                key={data.id}
                                                data={data}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                            <a className="button" href="/about">About Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;