import React from 'react'; 
import PageHeader from '../Components/common/PageHeader';

const ThankYouPage = () => {
    return (
        <>
            
            <div className="thank-you-content" style={styles.container}>
                <div style={styles.content}>
                    <h1 style={styles.title}>Thank You!</h1>
                    <p style={styles.message}>
                        Your message has been sent successfully. We will get back to you shortly.
                    </p>
                    <a href="/" style={styles.button}>
                        Return to Home
                    </a>
                </div>
            </div>
        </>
    );
};

// Inline styles (replace with your CSS classes if needed)
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f9f9f9',
    },
    content: {
        textAlign: 'center',
        maxWidth: '600px',
        padding: '30px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
        fontSize: '2.5rem',
        marginBottom: '20px',
        color: '#333',
    },
    message: {
        fontSize: '1.2rem',
        marginBottom: '30px',
        color: '#666',
    },
    button: {
        display: 'inline-block',
        padding: '10px 20px',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: '#007bff',
        textDecoration: 'none',
        borderRadius: '5px',
    },
};

export default ThankYouPage;
