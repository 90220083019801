import React from 'react';
import ServiceCard01_02 from '../../Components/ServiceCards/ServiceCard01_02';
import PaginationDiv from '../../Components/TeamElements/PaginationDiv';

const ServiceContainer02 = () => {
    const ServiceData02 = [
        {
            id: '1',
            img: 'images/services/17.png',
            bg: 'images/services/new.jpg',
            title: 'Residential New Build',
            description: 'JSB Building is the premier provider of residential new builds in Sydney, offering a comprehensive suite of services.',
            serviceLink: '/service-details',
            detailsLink: '/service-details',
        },
        {
            id: '2',
            img: 'images/services/18.png',
            bg: 'images/services/ext.jpg',
            title: 'Residential Extensions',
            description: 'JSB Buildings stands as a beacon of excellence in residential extensions, renowned for unparalleled craftsmanship.',
            serviceLink: '/services/residential-extensions',
            detailsLink: '/service-details/residential-extensions',
        },
        {
            id: '3',
            img: 'images/services/19.png',
            bg: 'images/services/ren.jpg',
            title: 'Residential Renovations',
            description: 'JSB Buildings is your premier choice for residential renovations in Melbourne, Victoria. With our expertise and commitment.',
            serviceLink: '/services/residential-renovations',
            detailsLink: '/service-details/residential-renovations',
        },
        {
            id: '4',
            img: 'images/services/20.png',
            bg: 'images/services/granny.jpg',
            title: 'Residential Granny Flat',
            description: 'JSB Buildings takes pride in being your premier choice for Granny Flats construction in Sydney.',
            serviceLink: '/services/residential-granny-flat',
            detailsLink: '/service-details/residential-granny-flat',
        },
    ];

    return (
        <div className="main_wrapper">
            <div className="section services">
                <div className="container">
                    <div className="row">
                        {
                            ServiceData02.map(data => (
                                <ServiceCard01_02
                                    key={data.id}
                                    data={data}
                                />
                            ))
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default ServiceContainer02;
