import React from 'react';
import AboutBelowContent02 from '../../Components/ExperienceBlocks/AboutBelowContent02';

const ExperienceAbout = () => {
    const headerData = {
        heading: 'Our 10 years of working experience.',
        subheading: 'WELCOME',
    };

    const ExpericenData = [
        {
            id: '1',
            title: '',
            description: 'Personalised Designs',
            img: 'images/about/t1.png'

        },
        {
            id: '2',
            title: 'Our Vision',
            description: 'Builderrine is the safe, reliable and cost effective builder company. We offer best construction Services.',
            img: 'images/funfact/smile.png'
        },
    ];
    return (
        <div className="experience">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            
                            <img src="images/funfact/smile.png" alt="" />
                            
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content about">
                            <div className="section_header">
                                <h6 className="section_sub_title">{headerData.subheading}</h6>
                                <h1 className="section_title">{headerData.heading}</h1>
                                <p className="section_desc">Founded with a vision to revolutionise the construction industry, 
                                    JSB Buildings Pty Ltd has grown from humble beginnings into a dynamic force driving change 
                                    in Sydney's architectural landscape. Led by Alaa Albourhan, our journey began over 7 years 
                                    ago with a passion for interior fit outs, specialising in gyprock and painting. Today, 
                                    we stand as a fully-fledged residential design and build builder, 
                                    committed to excellence in every aspect of our work.</p><br />
                                    <a className="button" href="/contact">Contact Us</a>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px' }}>
                                    <div style={{ flex: '1 1 45%', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <span style={{ marginRight: '10px' }}>✔</span>
                                        <span>Personalised Designs</span>
                                    </div>
                                    <div style={{ flex: '1 1 45%', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <span style={{ marginRight: '10px' }}>✔</span>
                                        <span>Quality Craftsmanship</span>
                                    </div>
                                    <div style={{ flex: '1 1 45%', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <span style={{ marginRight: '10px' }}>✔</span>
                                        <span>Stress-Free Process</span>
                                    </div>
                                    <div style={{ flex: '1 1 45%', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <span style={{ marginRight: '10px' }}>✔</span>
                                        <span>Timely Completion</span>
                                    </div>
                                    </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceAbout;