import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer_above">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer_widget footer_widget_padding">
                                <h4 className="widget_title">
                                    About JBS Buildings
                                </h4>
                                <p>Crafting dreams into reality, one project at a time. Trust JSB Buildings to build the home of your dreams.</p>
                                <div className="side_footer_social">
                                    <ul className="bottom_social">
                                        <li className="facebook"><a href="https://www.facebook.com/jsbbuildings"><i className="ion-social-facebook"></i></a></li>
                                        <li className="twitter"><a href="https://www.facebook.com/jsbbuildings"><i className="ion-social-twitter"></i></a></li>
                                        <li className="instagram"><a href="https://www.facebook.com/jsbbuildings"><i className="ion-social-instagram-outline"></i></a></li>
                                        <li className="linkedin"><a href="https://www.facebook.com/jsbbuildings"><i className="ion-social-linkedin-outline"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="footer_widget footer_links">
                                <h4 className="widget_title">
                                    Our Services
                                </h4>
                                <div className="footer_nav">
                                    <ul className="footer_menu">
                                        <li className="menu-item"><a href="/residentialrenovations">
                                        Residential Renovations</a></li>

                                        <li className="menu-item"><a href="/grannyflat">
                                        Residential Granny Flat</a></li>

                                        <li className="menu-item"><a href="/residentialextensions">
                                        Residential Extensions</a></li>

                                        <li className="menu-item"><a href="/newbuild">
                                        Residential New Build</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="footer_widget footer_links">
                                <h4 className="widget_title">
                                    Quick Links
                                </h4>
                                <div className="footer_nav">
                                    <ul className="footer_menu">
                                        <li className="menu-item"><a href="/about">About Us</a></li>
                                        <li className="menu-item"><a href="/services-2">Services</a></li>
                                        <li className="menu-item"><a href="/faq">FAQ</a></li>
                                        <li className="menu-item"><a href="/contact">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">
                                    Address
                                </h4>
                                <ul>
                                    <li><i className="fa fa-map-marker" aria-hidden="true"></i><span>Office in Bass Hill, New South Wales</span></li>
                                    <li><i className="fa fa-envelope" aria-hidden="true"></i><span>info@jsbbuildings.com.au</span></li>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i><span>(02) 8723 - 9960</span></li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="footer_bottom_inner">
                        <div className="logo">
                            <a href="index"><img src="images/JSB-white.svg" alt="Logo" /></a>
                        </div>
                        <div className="copyright">
                            <p>JSB Buildings © 2024. All Rights Reserved.</p>
                        </div>
                        <div className="footer_nav_bottom">
                            
                        </div>
                        <div className="totop">
                            <a href="#"><i className="ion-ios-arrow-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;